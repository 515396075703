<i18n locale="th" lang="yaml" >
    refer.from: 'งานจาก'
    refer.to: 'ถูกส่งต่อให้'
</i18n>
<template>
    <div class="drone-service-refer-history">
        <span>
            {{$t('refer.from')}} "{{fromCompany}}" {{$t('refer.to')}} "{{toCompany}}"
        </span> <br />
        <span>( <span v-if="referToServiceNo">{{referToServiceNo}}</span><span v-else>-</span> )</span> 
    </div>
</template>

<script>
export default {
	components : {
} ,
	props : {
		history : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
        fromCompany(){
            return this.history.data.from_company.name;
        },
        toCompany(){
            return this.history.data.to_company.name;
        },
        referToServiceNo(){
            return this.history.data.refer_to_service_no;
        }
	}
}
</script>

<style lang="less" scoped>
.drone-service-refer-history{
    padding: 3px 0;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    text-align: center;
}
</style>
