<template>
	<a-timeline-item>
		<component
			:is="timelineIconComponent.component"
			slot="dot"
			v-bind="timelineIconComponent.props"/>
		<component :is="timelineContentComponent.component" :history="history" :show-relate-link="showRelateLink" v-on="$listeners"/>
	</a-timeline-item>
</template>
<script>
import {Icon,Timeline} from "ant-design-vue"
import DroneIcon from "@components/icon/DroneIcon.vue"
import ServiceIcon from "vue-material-design-icons/HammerScrewdriver.vue"
import CommentIcon from "vue-material-design-icons/Comment.vue"
import DroneTicketHistoryCard from "@components/drone/history/DroneTicketHistoryCard.vue"
import DroneLogHistoryCard from "@components/drone/history/DroneLogHistoryCard.vue"
import DroneServiceHistoryCard from "@components/drone/history/DroneServiceHistoryCard.vue"
import DroneTicketCommentHistoryCard from "./DroneTicketCommentHistoryCard.vue"
import DroneReferServiceHistoryCard from "./DroneReferServiceHistoryCard.vue"
export default {
	components : {
		"a-timeline-item" : Timeline.Item
	} ,
	props : {
		history : {
			type : null,
			default : () => []
		},
		isRelateTimeline: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		timelineIconComponent() {
			const tlIconObj = {
				component: null,
				props: undefined
			};

			switch (this.history.type) {
				case 'ticket':
					tlIconObj.component = Icon;
					tlIconObj.props = {
						type: 'tool' ,
						class: 'timeline-icon text-error'
					};
					break;
				case 'log':
					if (this.history.data.action == 'update') {
						tlIconObj.component = Icon;
						tlIconObj.props = {
							type: 'dashboard' ,
							class: 'timeline-icon text-info'
						};
					}
					else{
						tlIconObj.component = DroneIcon;
						tlIconObj.props = {
							class: 'timeline-icon'
						};
					}
					break;
				case 'service':
					tlIconObj.component = ServiceIcon;
					tlIconObj.props = {
						class: 'timeline-icon'
					};
					break;
				case 'ticket_comment':
					tlIconObj.component = CommentIcon;
					tlIconObj.props = {
						class: 'timeline-icon text-error'
					};
					break;
				default:
					tlIconObj.component = null;
					break;
			}
			return tlIconObj;
		} ,
		timelineContentComponent() {
			const tlContentComponent = {
				component: null
			};
			switch (this.history.type){
				case 'ticket':
					tlContentComponent.component = DroneTicketHistoryCard;
					break;
				case 'log':
					tlContentComponent.component = DroneLogHistoryCard;
					break;
				case 'service':
					tlContentComponent.component = DroneServiceHistoryCard;
					break;
				case 'ticket_comment':
					tlContentComponent.component = DroneTicketCommentHistoryCard;
					break;
				case 'service_refer':
					tlContentComponent.component = DroneReferServiceHistoryCard;
					break;
				default:
					tlContentComponent.component = null;
					break;
			}
			return tlContentComponent
		},
		showRelateLink(){
			return !this.isRelateTimeline;
		}
	}
}
</script>

<style lang="less" scoped>
.timeline-icon {
	font-size : 1.25em;
}
</style>
